.appBg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
}
.loginForm {
  background-color: rgba(255, 255, 255, 0.33);
  box-shadow: 0px 2px 8px 0 rgba(46, 46, 254, 0.3);
  padding: 16px 16px 16px 16px;
  border-radius: 16px;
  width: 350px;
  height: 350px;
}

.btn {
  border-radius: 6px !important;
  background: rgba(61, 61, 252, 0.833) !important;
  border: none !important;
  padding: 18px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
